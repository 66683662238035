import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./Auth";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
export const UserContext = createContext();

export default function AuthProvider(props) {
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [exchangeBalance, setExchangeBalance] = useState({});
  const getViewMyProfile = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      if (res.data.statusCode === 200) {
        setProfile(res.data.result);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);

      // if (error.response.data.responseMessage === "jwt malformed" || "jwt expired") {
      //   history.push("/login");
      //   window.localStorage.removeItem("token");
      //   window.localStorage.removeItem("email");
      // }
    
      
    }
  };
  const exchangeWallet = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.exchangeWallet,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        setExchangeBalance(res.data.result);
      } else {
        // toast.error(res.data.responseMessage);
      }
    } catch (error) {}
  };


  let data = {
    profile,
    exchangeBalance,
    getViewMyProfile: () => getViewMyProfile(),

    exchangeWallet: () => exchangeWallet(),
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
