import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AOS from "aos";
import { Link, useLocation } from "react-router-dom";
import "aos/dist/aos.css";

AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    // overflow: "hidden",
    width: "100%",
  },
  backtext: {
    backgroundImage: "url('/images/div.svg')",
    backgroundSize: "cover",
    width: "100%",
    maxWidth: "400px",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    // padding:"0 97px",
    margin: "0px",
  },
  imagelogo: {
    width: "152px",
    paddingTop: "17px",
    paddingLeft: "16px",
  },
}));

const TestLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className={classes.root}>
      <div className={classes.backtext}>
        {" "}
        <Link to="/">
          <img
            className={classes.imagelogo}
            src="/images/logo.png"
            alt="loader"
          />
        </Link>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

TestLayout.propTypes = {
  children: PropTypes.node,
};

export default TestLayout;
