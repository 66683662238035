import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";

AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    height: "100%",
    // overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // backgroundImage:`url("/images/landing page.png")`,
    backgroundPosition: "unset",
    backgroundSize: "cover",
    minHeight: "100vh",
    backgroundColor: "#0D2124"
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    // padding:"0 97px",
    margin: '0px'
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    // <div className={classes.root}>
    //   {/* <TopBar /> */}
    //   <div className={classes.wrapper}>
    //     <div className={classes.contentContainer}>
    <div className={classes.content}>{children}</div>
    //     </div>
    //   </div>
    // </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
